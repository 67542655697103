<template>
  <div class="card" v-if="nuevoProducto">
    <!-- <CrudProveedor></CrudProveedor> -->
  </div>
  <ProductoCreate
    :mostrar="mostrarProductoModal"
    :producto="producto"
    @closeModal="cerrarModal"
    @actualizarListadoProductos="obtenerListadoProductos"
  ></ProductoCreate>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Par&aacute;metros</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Productos</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <router-link to="/productos" class="text-900 line-height-3"
        >Listado de Productos</router-link
      >
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Productos/Servicios
          <Badge size="xlarge" severity="success"> {{ totalRecords }}</Badge>
        </div>
      </div>
      <div class="flex mt-3 lg:mt-0">
        <Button
          label="Nuevo"
          v-if="'Producto Crear' in auth.user.permissions"
          class="p-button-outlined mr-2 p-button-lg"
          icon="pi pi-plus"
          v-tooltip.top="'Registrar Nuevo Producto'"
          @click="openModal"
          :disabled="!cargando ? false : true"
        ></Button>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtlaboratorios"
        dataKey="id"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[20, 100, 500, 1000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Productos"
        responsiveLayout="scroll"
        stripedRows
        showGridlines
        filterDisplay="menu"
        :value="productos"
        :loading="cargando"
        :paginator="true"
        :filters="buscar"
        :rows="20"
        :rowHover="true"
        :lazy="true"
        :totalRecords="totalRecords"
        v-model:filters="buscar"
        @page="onPage($event)"
        @sort="onSort($event)"
        @filter="onFilter($event)"
        selectionMode="single click"
        @row-dblclick="editarProducto($event.data)"
      >
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>

        <Column
          field="codigo_interno"
          header="CÓD.INTERNO"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
          style="width: 30px"
          :class="'text-right'"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="CÓD..."
            />
          </template>
        </Column>
        <Column
          field="descripcion"
          header="DESCRIPCIÓN/DETALLE"
          style="width: 300px; font-weight: bold"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
        >
          {{ data.descripcion }}
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="DESCRIPCIÓN/DETALLE"
            />
          </template>
        </Column>
        <Column
          field="grupo.nombre"
          header="GRUPO"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
        >
          {{ data.grupo.nombre }}
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="GRUPO"
            />
          </template>
        </Column>
        <Column
          field="imagen"
          header="IMAGEN"
          v-if="'Producto VerImagen' in auth.user.permissions"
        >
          <template #body="{ data }">
            <template v-if="data.imagen != null">
              <Image
                :src="'data:image/png;base64, ' + data.imagen"
                width="30"
                preview
              ></Image>
            </template>
            <template v-else><p>---</p></template>
          </template>
        </Column>
        <!-- <Column field="fabrica_id" header="Codigo Laboratorio">
          {{ data.fabrica_id }}
        </Column> -->
        <Column
          field="fabricas.nombre"
          header="MARCA"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
        >
          {{ data.fabricas.nombre }}
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="MARCA"
            />
          </template>
        </Column>
        <!-- <Column field="fabrica_id" header="Codigo Laboratorio">
          {{ data.fabrica_id }}
        </Column> -->
        <Column field="unidad_medida_nombre" header="U.MEDIDA">
          {{ data.unidad_medida_nombre }}
        </Column>
        <Column class="text-center" field="stock_actual" header="S.ACTUAL">
          <template #body="{ data }">
            <div v-if="data.tipo_catalogo == 1" :class="claseEstado2(data)">
              {{ sinDecimal(data.stock_actual) }}
            </div>
            <div v-else>SERVICIO</div>
          </template>
        </Column>
        <!-- <Column field="stock_minimo" header="S.MÍN" class="text-right">
          {{ convertirNumeroGermanicFormat(data.stock_minimo) }}
        </Column> -->
        <!-- <Column
          field="stock_maximo"
          header="S.MÁX"
          class="text-right"
        
        >
          {{ data.stock_maximo }}
        </Column> -->
        <Column field="precio_compra" header="P.COMPRA" class="text-right">
          {{ convertirNumeroGermanicFormat(data.precio_compra) }}
        </Column>
        <Column field="precio_sugerido" header="P.VENTA" class="text-right">
          <template #body="{ data }">
            <span v-if="data.precio_venta == 0">
              {{ convertirNumeroGermanicFormat(data.precio_sugerido) }}
            </span>
            <span v-else>
              {{ convertirNumeroGermanicFormat(data.precio_venta) }}
            </span>
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado.value]">
              {{ data.estado.label }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              v-tooltip.top="'Ver Acciones'"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      v-model:visible="deleteProdModal"
      :style="{ width: '450px' }"
      header="Confirmar"
      :modal="true"
    >
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="producto"
          >Estas Seguro que quieres eliminar producto:
          <b>{{ producto.descripcion }}</b> ?</span
        >
      </div>
      <template #footer>
        <Button
          label="No, Cancelar"
          icon="pi pi-times"
          class="p-button-success p-button-lg"
          @click="deleteProdModal = false"
        />
        <Button
          label="Si Eliminar"
          icon="pi pi-check"
          class="p-button-danger p-button-lg"
          @click="deleteProducto"
        />
      </template>
    </Dialog>
    <ProductoImagenes
      :show="verImagenes"
      :producto_id="ProductoImagenes"
      @closeModal="ocultarDialog"
      @actualizarListado="obtenerListadoProductos"
    ></ProductoImagenes>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "@/service/ProductService";
import ProductoCreate from "@/module/productos/ProductoCreate.vue";
import { useAuth } from "@/stores";
import ProductoImagenes from "@/module/productos/ProductoImagenes.vue";
//nuevo o editar
export default {
  components: { ProductoCreate, ProductoImagenes },
  data() {
    return {
      nuevoProducto: false,
      totalRegistros: 0,
      totalRecords: 0,
      productos: [],
      cargando: true,
      mostrarProductoModal: false,
      producto: {},
      deleteProdModal: false,
      buscar: null,
      lazyParams: {},
      claseEstado: ["status-outofstock", "status-instock"],
      claseEstado2: (data) => {
        if (data.stock_actual <= 0) {
          return "outofstock";
        } else if (data.stock_actual <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      verImagenes: false,
      ProductoImagenes: null,
      ultimo_codigo_interno: null,
    };
  },
  productoService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.productoService = new ProductService();
    this.initBuscar();
  },
  mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dtlaboratorios.rows,
      sortField: null,
      sortOrder: null,
      filters: this.buscar,
    };
    this.obtenerListadoProductos();
  },
  methods: {
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
      }).format(numero);
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    onPage(event) {
      this.lazyParams = event;
      this.obtenerListadoProductos();
    },
    onSort(event) {
      this.lazyParams = event;
    },
    onFilter() {
      this.lazyParams.filters = this.buscar;
      this.obtenerListadoProductos();
    },
    acciones(datos) {
      let permisosUsuarioAuth = this.auth.user.permissions;
      return [
        {
          label: "Editar",
          disabled: "Producto Editar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-pencil",
          command: () => {
            this.editarProducto(datos);
          },
        },
        {
          label: "Ver Detalle",
          disabled:
            "Producto Ver Detalle" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-eye",
          command: () => {
            this.verDetalle(datos);
          },
        },
        {
          label: "Ver Imagenes",
          visible: "Producto VerImagen" in permisosUsuarioAuth ? true : false,
          icon: "pi pi-image",
          command: () => {
            this.mostrar_imagenes(datos.id);
          },
        },
        {
          label: "Eliminar",
          disabled: "Producto Eliminar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-trash",
          command: () => {
            this.confirmDeleteProducto(datos);
          },
        },
      ];
    },
    ocultarDialog() {
      this.verImagenes = false;
    },
    mostrar_imagenes(producto_id) {
      this.ProductoImagenes = producto_id;
      this.verImagenes = true;
    },
    openModal() {
      this.producto = {
        estado: { label: "Activo", value: 1 },
        stock_minimo: 0,
        stock_maximo: 0,
        precio_sugerido: 0,
        tipo_catalogo: 1,
        codigo_interno: this.ultimo_codigo_interno ?? "",
      };
      this.mostrarProductoModal = true;
    },
    cerrarModal() {
      this.mostrarProductoModal = false;
      this.producto = {};
    },
    obtenerListadoProductos() {
      this.cargando = true;

      setTimeout(() => {
        this.productoService
          .getAllProductos({ dt_params: JSON.stringify(this.lazyParams) })
          .then((data) => {
            this.totalRecords = data.productos.total || 0;
            this.productos = data.productos.data || [];
            if (this.productos.length > 0) {
              this.ultimo_codigo_interno =
                data.productos.data[0].codigo_interno &&
                !isNaN(data.productos.data[0].codigo_interno)
                  ? parseInt(data.productos.data[0].codigo_interno) + 1
                  : data.productos.data[0].codigo_interno ?? "";
            }
            this.cargando = false;
            this.productos.forEach((producto) => {
              producto.created_at = new Date(producto.created_at);
              producto.updated_at = new Date(producto.updated_at);
              producto.estado = {
                label: this.textoEstado(producto.estado),
                value: producto.estado,
              };
            });
          });
      }, Math.random() * 1000 + 250);
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
    editarProducto(info_producto) {
      this.producto = { ...info_producto };
      this.producto.stock_minimo = parseInt(this.producto.stock_minimo || 0);
      this.producto.stock_maximo = parseInt(this.producto.stock_maximo || 0);
      this.producto.precio_sugerido = parseFloat(
        this.producto.precio_sugerido || 0
      );
      this.producto.precio_compra = parseFloat(
        this.producto.precio_compra || 0
      );
      this.producto.stock_actual = parseInt(this.producto.stock_actual || 0);
      this.producto.tipo_catalogo = parseInt(this.producto.tipo_catalogo || 1);

      this.mostrarProductoModal = true;
    },
    verDetalle(producto) {
      this.$router.push(`/producto/${producto.id}/detalle`);
    },
    confirmDeleteProducto(producto) {
      this.producto = { ...producto };
      this.deleteProdModal = true;
    },
    deleteProducto() {
      this.productoService.deleteProducto(this.producto).then((response) => {
        if (response.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: response.mensaje,
            life: 3000,
          });
          this.productos = this.productos.filter(
            (val) => val.id !== this.producto.id
          );
          this.deleteProdModal = false;
          this.producto = {};
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.mensaje,
            life: 3000,
          });
          this.deleteProdModal = false;
        }
      });
    },
    initBuscar() {
      this.buscar = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        codigo_interno: { value: null, matchMode: FilterMatchMode.EQUALS },
        codigo_barra: { value: null, matchMode: FilterMatchMode.EQUALS },
        descripcion: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "fabricas.nombre": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "grupo.nombre": { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
</style>
